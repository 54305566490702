//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import transition from "~/assets/js/transition";

gsap.registerPlugin(ScrollTrigger);

export default {
  transition,
  data() {
    return {
      isVideoPlaying: false,
      // isVideoTeamPlaying: false,
      projects: [
        {
          title: "Fratelli Cucine",
          text: "Le showroom qui donne envie de cuisiner italien",
          image: "mockup-fratelli-home.png",
          width: "822",
          height: "504",
          alt: "projet Fratelli Cucine",
          class: "lg:col-span-7 flex items-end",
          link: "fratelli-cucine",
        },
        {
          title: "Cabinet Stella",
          text: "Le cabinet d’expert comptable qui maîtrise son budget com’",
          image: "mockup-stella-home.png",
          width: "578",
          height: "690",
          alt: "projet Cabinet Stella",
          class: "lg:col-span-5 lg:mt-[-12%]",
          link: "stella-compta",
        },
        {
          title: "Nancy Open Air",
          text: "L'événement musical qui détrône Woodstock",
          image: "mockup-noa-home.png",
          width: "578",
          height: "680",
          alt: "projet Nancy Open air",
          class: "lg:col-span-5",
          link: "nancy-open-air",
        },
        {
          title: "Islekter",
          text: "L'artisan qui aime que les choses soient carrées",
          image: "mockup-islekter-home.png",
          width: "822",
          height: "493",
          alt: "projet Islekter",
          class: "lg:col-span-7",
          link: "islekter",
        },
        {
          title: "Velaine Immo",
          text: "L'agence immobilière qui donne envie de vivre en Meuse",
          image: "mockup-velaine-immo-home.png",
          width: "822",
          height: "504",
          alt: "projet Velaine Immo",
          class: "lg:col-span-7 flex items-end",
          link: "velaine-immo",
        },
        {
          title: "Goddyn's",
          text: "Les petits gars du coin qui font les cakes",
          image: "mockup-goddyns-home.png",
          width: "578",
          height: "680",
          alt: "projet Goddyns",
          class: "lg:col-span-5 lg:mt-[-30%]",
          link: "goddyns",
        },
      ],

      visions: [
        {
          title: "Donnant-Donnant",
          content:
            "La créativité naît lorsque toutes les barrières tombent entre vous et nous. On peut alors aller au fond des choses et trouver des solutions qui vous correspondent vraiment. Le secret ? La confiance.",
          color: "#FEF6EC",
          background: "#FD6C43",
          icon: require("~/assets/img/vision.svg"),
        },
        {
          title: "Promesse tenue",
          content:
            "Vous emmener là où vous devez être. On déploie toutes nos compétences pour vous faire atteindre vos objectifs et même les dépasser. Vous allez être surpris par tout le potentiel que vous recelez.",
          color: "#F6FFD0",
          background: "#004646",
          icon: require("~/assets/img/mission.svg"),
        },
        {
          title: "Défi relevé",
          content:
            "Notre mission, créer avec conviction, tout simplement. On pense ce qu’on dit, on dit ce qu’on pense. On n’est pas là pour vous flatter, mais pour tirer le meilleur de vous, alors on se donne à fond pour y parvenir.",
          color: "#CDF5F2",
          background: "#2A47E9",
          icon: require("~/assets/img/promesse.svg"),
        },
        {
          title: "Valeur absolue",
          content:
            "Avancer ensemble avec humanité. Ici personne ne sait tout, mais tout le monde sait quelque chose d'essentiel à la concrétisation de votre projet. On cultive l’esprit d’équipe et l’intelligence collective et c’est ce qui fait notre force.",
          color: "#FFE1F3",
          background: "#927",
          icon: require("~/assets/img/valeurs.svg"),
        },
      ],

      goals: [
        {
          number: "2014",
          content: "L’année où tout a commencé",
        },
        {
          number: "2",
          content: "Premières places aux trophées de la communication 2023",
        },
        {
          number: "10",
          content: "Créatifs au service de vos ambitions",
        },
        {
          number: "136",
          content: "Projets bouclés avec succès en 2023",
        },
      ],

      services: [
        {
          title: "Graphisme",
          content: `
          <p class="mb-4">Nous considérons chaque projet comme une opportunité de créer quelque chose d’exceptionnel.</p>
          <p class="mb-4">Entre logo mémorable et graphisme innovant, NANCOMCY capture l’essence de votre identité de marque et repousse les limites du design pour créer une histoire visuelle captivante.</p>
          <p>Des concepts accrocheurs et des illustrations sur mesure qui, une fois déclinés sous forme de flyers, cartes de visite ou supports d’affichage, transcendent votre identité visuelle pour vous rendre inoubliable.</p>
          `,
          image: "service-graphisme.png",
          services: [
            "Logotype",
            "Charte graphique",
            "Identité visuelle",
            "Illustration",
            "Supports imprimés",
            "Signalétique",
          ],
          link: "graphisme",
          active: false,
        },
        {
          title: "Création de site internet",
          content: `
          <p class="mb-4">Nous avons une obsession, vous mettre en lumière. Pour ce faire, nous mettons à la disposition de votre marque ce qui se fait de mieux en termes de webdesign et webmarketing.</p>
          <p class="mb-4">Qu’il s’agisse de la refonte d’un site web existant, ou du développement d’un site internet sur mesure, nous faisons en sorte que vous restiez toujours à la page pour optimiser votre présence en ligne.</p>
          <p>Votre entreprise acquiert une image professionnelle et moderne, fidèle à vos spécificités et à ce qui vous différencie de la concurrence.</p>
          `,
          image: "service-siteweb.png",
          services: [
            "Sites web sur mesure",
            "Landing pages",
            "Responsive design",
            "UI/UX Design",
            "Rédaction personnalisée",
          ],
          link: "web",
          active: false,
        },
        {
          title: "Marketing",
          content: `
          <p class="mb-4">Nous donnons du sens à votre stratégie de marque et nous définissons vos leviers de diffusion pour faire mouche.</p>
          <p class="mb-4">Du naming à la création d’un univers, d’audit en plateforme de marque, nous sortons tout notre arsenal d’expertises pour identifier vos besoins et définir les solutions qui vous propulsent vers vos objectifs.</p>
          <p>Nous stimulons votre stratégie de contenus, nous renforçons votre présence en ligne et nous concevons des campagnes publicitaires fortes et impactantes. C’est presque aussi simple que ça.</p>
          `,
          image: "service-marketing.png",
          services: [
            "Audit",
            "Plateforme de marque",
            "Naming",
            "Stratégie de com",
            "Ateliers de réflexion",
          ],
          link: "strategy",
          active: false,
        },
        {
          title: "Motion design",
          content: `
          <p class="mb-4">Nous tirons parti de la puissance de l’animation 2D et 3D pour donner à votre message une dimension forte et mémorable.</p>
          <p class="mb-4">Notre studio d’animation s’attache à vous différencier de la concurrence, à vous faire sortir du lot avec des formats modernes et innovants et à prendre en charge l’ensemble du processus de création, du montage à l’animation graphique en passant par la conception sonore et l’étalonnage.</p>
          <p>Vos cibles se souviendront de vous à coup sûr et vous obtiendrez un taux de conversion plus rapide et ciblé avec une vidéo en motion design qu’avec n’importe quel autre support.</p>
          `,
          image: "service-motiondesign.png",
          services: [
            "Animation 2D/3D",
            "Habillage vidéo",
            "Vidéo de présentation",
            "Sound design",
          ],
          link: "motion",
          active: false,
        },
        {
          title: "Social media",
          content: `
          <p class="mb-4">Nous repensons votre présence digitale et l’expérience utilisateur de votre cible pour générer plus de trafic sur vos réseaux et un meilleur engagement de votre communauté.</p>
          <p class="mb-4">Reconnue comme une des meilleures agences conseil en media marketing et social Meta Ads, nous mettons un point d’honneur à développer votre visibilité et votre notoriété en ligne.</p>
          <p>Nous créons du contenu engageant et nous vous en rendons compte grâce à une analyse précise des résultats de chaque campagne. Vous êtes présents, on vous reconnaît, on vous fait confiance. Défi relevé !</p>
          `,
          image: "foodlab-social-media.jpg",
          services: ["Gestion de réseaux", "Campagne pub", "Motion & Reels"],
          link: "social",
          active: false,
        },

        {
          title: "SEO",
          content: `
          <p class="mb-4">Nous activons votre présence en ligne en vous faisant émerger du sempiternel flot des résultats de recherche.</p>
          <p class="mb-4">Pour booster votre référencement, nos consultants SEO rédigent des contenus riches en mots-clés pour attirer l’attention des moteurs de recherche, procèdent à des optimisations techniques pour améliorer votre positionnement, et développent des stratégies de netlinking pour renforcer la qualité des liens pointant vers votre site. </p>
          <p>Si vous n’avez rien compris, dites-vous simplement que votre CA va faire un bond de géant.</p>
          `,
          image: "service-seo.png",
          services: ["Référencement naturel", "Rédaction optimisée"],
          link: "referencement",
          active: false,
        },
        {
          title: "Vidéo",
          content: `
          <p class="mb-4">Nous racontons votre histoire, en quelques secondes, en quelques minutes, nous offrons à votre audience une expérience visuelle unique.</p>
          <p class="mb-4">Du clip promotionnel aux témoignages clients, en passant par la vidéo explicative ou le film d’entreprise, nous veillons à ce que chaque élément visuel, sonore ou narratif produit par notre studio corresponde à votre univers et à votre identité.</p>
          <p>Adaptables à toutes les plateformes, la vidéo est un atout précieux pour une stratégie de référencement efficace, elle vient renforcer votre présence en ligne et votre notoriété.</p>
          `,
          image: "service-video.png",
          services: [
            "Interviews",
            "Films d'entreprise",
            "Courts-métrages",
            "Publicité",
            "Captation événementiel",
            "Drone",
          ],
          link: "video",
          active: false,
        },
      ],
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
    this.$nextTick(() => {
      const interval = setInterval((th) => {
        this.$refs.preloader.onAnimComplete();
        clearInterval(interval);
      }, 1000);
    });

    this.pinEffectHero();
    this.projectAnimation();
    this.bigNAnimation();
    this.bigCoinsAnimation();
    this.pinkGridAnimation();
    this.coinRightAnimation();
    this.coinLeftAnimation();
  },

  beforeDestroy() {
    ScrollTrigger.getAll().forEach((t) => t.kill());
  },

  methods: {
    pinEffectHero() {
      const mm = gsap.matchMedia();
      mm.add(
        {
          isDesktop: "(min-width: 1024px)",
          isMobile: "(max-width: 1023px)",
        },
        (context) => {
          const { isDesktop } = context.conditions;
          gsap.to(".to-pin", {
            scrollTrigger: {
              trigger: ".section-hero-home",
              scrub: true,
              pin: ".to-pin",
              end: isDesktop ? "bottom 60%" : "bottom 30%",
            },
            opacity: 0,
          });

          if (isDesktop) {
            gsap.to(".between-video", {
              scrollTrigger: {
                trigger: ".between-video",
                scrub: true,
                start: "top 60%",
                end: "top 40%",
              },
              opacity: "0",
            });
          }
        }
      );

      gsap.to(".mockup-hero", {
        scrollTrigger: {
          trigger: ".mockup-hero",
          scrub: true,
        },
        width: "90%",
      });
    },

    projectAnimation() {
      const projects = gsap.utils.toArray(".project");
      const mm = gsap.matchMedia();
      mm.add(
        {
          isDesktop: "(min-width: 1024px)",
          isMobile: "(max-width: 1023px)",
        },
        (context) => {
          const { isDesktop } = context.conditions;
          projects.forEach((project) => {
            gsap.to(project, {
              scrollTrigger: {
                trigger: project,
                start: isDesktop ? "top 60%" : "top 85%",
              },
              opacity: 1,
            });
          });
        }
      );
    },

    toggleService(index) {
      this.services.forEach((service, i) => {
        if (index !== i) {
          service.active = false;
        }
      });
      this.services[index].active = !this.services[index].active;
    },

    bigNAnimation() {
      gsap.to(".big-n", {
        scrollTrigger: {
          trigger: ".section-goals",
          start: "center 50%",
        },
        filter: "drop-shadow(0 42.375px 60.971px #f05225)",
        duration: 1.2,
      });
    },

    toggleVideo() {
      this.isVideoPlaying = !this.isVideoPlaying;
      if (this.isVideoPlaying) {
        this.$refs.video.play();
      } else {
        this.$refs.video.pause();
      }
    },

    // toggleVideoTeam() {
    //   this.isVideoTeamPlaying = true;
    //   this.$refs.videoTeam.play();
    // },

    bigCoinsAnimation() {
      gsap.to(".big-coins", {
        scrollTrigger: {
          trigger: ".section-budget",
          scrub: true,
        },

        y: -100,
      });
    },

    pinkGridAnimation() {
      gsap.to(".pink-grid", {
        scrollTrigger: {
          trigger: ".section-budget",
          scrub: true,
        },

        y: -300,
      });
    },

    coinRightAnimation() {
      gsap.to(".coin-right", {
        scrollTrigger: {
          trigger: ".section-budget",
          scrub: true,
        },

        y: -150,
        rotate: 15,
      });
    },

    coinLeftAnimation() {
      gsap.to(".coin-left", {
        scrollTrigger: {
          trigger: ".section-budget",
          scrub: true,
        },

        y: -150,
        rotate: 50,
      });
    },
  },
};
