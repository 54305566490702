import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6b3a5e9b&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=6b3a5e9b&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b3a5e9b",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=router"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Preloader: require('/app/components/Preloader.vue').default,Header: require('/app/components/Header.vue').default,Button: require('/app/components/Button.vue').default,IconsArrowRightAltRounded: require('/app/components/Icons/ArrowRightAltRounded.vue').default,Testimonial: require('/app/components/Testimonial.vue').default,Faq: require('/app/components/Faq.vue').default,Footer: require('/app/components/Footer.vue').default,PopupVideo: require('/app/components/PopupVideo.vue').default,Pointer: require('/app/components/Pointer.vue').default})
